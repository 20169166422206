import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type LoaderFunctionArgs,
  type HeadersFunction,
  json,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import { Outlet, useLoaderData, useNavigation } from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useEffect, useRef } from 'react';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';

import { Document } from '#app/components/app/Document.tsx';
import { Confetti } from '#app/components/confetti.tsx';
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx';
import { useOptionalUser } from '#app/utils/user.ts';

import { EpicProgress } from './components/progress-bar.tsx';
import { useToast } from './components/toaster.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import { useTheme } from './routes/resources+/theme/index.tsx';
import { getTheme } from './routes/resources+/theme/theme.server.ts';
import fontStyleSheetUrl from './styles/font.css';
import rootStylesheetUrl from './styles/root.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.tsx';
import { getHints } from './utils/client-hints.tsx';
import { getConfetti } from './utils/confetti.server.ts';
import { csrf } from './utils/csrf.server.ts';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useUserAgent } from './utils/useragent-provider.ts';

import "simplebar-react/dist/simplebar.min.css?__remix_sideEffect__";

/* const RemixDevTools =
	process.env.NODE_ENV === 'development' && process.env.DEV_TOOLS === 'true'
		? lazy(() => import('remix-development-tools'))
		: null; */

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: rootStylesheetUrl, as: 'style' },
  {
    rel: 'preload',
    href: 'https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css',
    as: 'style'
  },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const, // necessary to make typescript happy
  /* { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' }, */
  // These should match the css preloads above to avoid css as render blocking resource
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  { rel: 'stylesheet', href: rootStylesheetUrl },
  {
    rel: 'stylesheet',
    href: 'https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css'
  },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  {
    title: data ?
    'MeinTraktor HändlerLogin' :
    'Error | MeinTraktor HändlerLogin',
    charset: 'utf-8',
    viewport: 'width=device-width,initial-scale=1'
  }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        name: true,
        username: true,
        image: { select: { id: true } },
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const { confettiId, headers: confettiHeaders } = getConfetti(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      confettiId,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        confettiHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
};

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  const navigation = useNavigation();
  const user = useOptionalUser();
  const navTimingData = useRef<{state: string;time: number;}>({
    state: 'idle',
    time: 0
  });

  const userAgent = useUserAgent();
  const { isIE, isLegacyEdge } = userAgent ?
  getSelectorsByUserAgent(userAgent) :
  { isIE: false, isLegacyEdge: false };

  useToast(data.toast);

  if (
  navigation.state === 'loading' &&
  navTimingData.current.state === 'idle')
  {
    navTimingData.current.state = 'loading';
    navTimingData.current.time = new Date().getTime();
  } else if (
  navigation.state === 'idle' &&
  navTimingData.current.state === 'loading')
  {
    navTimingData.current.state = 'idle';
    console.log(
      'finished navigation; time it took',
      (new Date().getTime() - navTimingData.current.time) / 1000,
      's'
    );

    navTimingData.current.time = 0;
  }

  useEffect(() => {
    // @ts-ignore
    if (user && typeof window !== undefined && window._mfq) {
      const isAdmin = !!user.roles.find((role) => role.name === 'admin');
      const isWerkstatt = !!user.roles.find((role) => role.name === 'Werkstatt');
      const isAussendienst = !!user.roles.find(
        (role) => role.name === 'Außendienst'
      );
      const role = isAdmin ?
      'Admin' :
      isWerkstatt ?
      'Werkstatt' :
      isAussendienst ?
      'Außendienst' :
      'Händler';
      // @ts-ignore
      window._mfq.push(['setVariable', 'ROLE', role]);
    }
  }, [user]);

  if (isIE || isLegacyEdge) {
    return <div>Unsupported Browser</div>;
  }

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<Outlet />
			<Confetti id={data.confettiId} />
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>);

}

// This imports the dev tools only if you're in development
/* if (process.env.NODE_ENV === 'development') {
	const { withDevTools } = await import('remix-development-tools');
	AppExport = withDevTools(AppExport);
} */

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}
export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}