import {
	Links,
	LiveReload,
	Meta,
	Scripts,
	ScrollRestoration,
} from '@remix-run/react';

import { ClientHintCheck } from '#app/utils/client-hints.tsx';
import { Monitoring } from 'react-scan/monitoring/remix';

export function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
}: {
	children: React.ReactNode;
	nonce: string;
	theme?: 'dark' | 'light';
	env?: Record<string, string>;
}) {
	return (
		<html lang="de" className={`${theme} h-full`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
				<script src="https://unpkg.com/react-scan/dist/install-hook.global.js" />
			</head>
			<body className="bg-background text-foreground">
				<Monitoring
					apiKey="Y6DTzkBeUD58BE_R3k_U2yxlBZ4gcsHm" // Safe to expose publically
					url="https://monitoring.react-scan.com/api/v1/ingest"
				/>
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
				{process.env.NODE_ENV === 'production' && (
					<script
						type="text/javascript"
						nonce={nonce}
						dangerouslySetInnerHTML={{
							__html: `
			window._mfq = window._mfq || [];
				(function () {
				var mf = document.createElement("script");
				mf.type = "text/javascript"; mf.defer = true;
				mf.src = "//cdn.mouseflow.com/projects/dbb34fe5-7b90-4ba1-b120-bce7aa61c1da.js";
				document.getElementsByTagName("head")[0].appendChild(mf);})();
			`,
						}}
					></script>
				)}
			</body>
		</html>
	);
}
